<template>
  <div v-if="is('super-admin')">
    <SuperAdminDashboard></SuperAdminDashboard>
  </div>
  <div v-if="is('realtor')">
    <RealtorDashboard></RealtorDashboard>
  </div>
</template>

<script>
import SuperAdminDashboard from "@/views/Dashboard/superAdmin";
import RealtorDashboard from "@/views/Dashboard/realtor";

export default {
  components: {RealtorDashboard, SuperAdminDashboard},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
