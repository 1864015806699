import BaseService from "./base.service";

class LeadsService {
    allleads(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('leads/allleads', page, params);
    }
    newleads(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('leads/newleads', page, params);
    }
    leadtag() {
        return BaseService.apiGetRequest('leads/leadtag');
    }
    exportcsvLeads() {
        return BaseService.apiGetRequest('getleads/exportcsvleads');
    }
    leads(page = null, search = null, filter_name=null, filter_email=null, filter_phone=null, filter_source=null, pageSize=null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        if(filter_name) {
            params.push({'key':'name', 'value':filter_name});
        }
        if(filter_email) {
            params.push({'key':'email', 'value':filter_email});
        }
        if(filter_phone) {
            params.push({'key':'phone', 'value':filter_phone});
        }
        if(filter_source) {
            params.push({'key':'source', 'value':filter_source});
        }
        if(pageSize) {
            params.push({'key':'per_page', 'value':pageSize});
        }
        return BaseService.apiGetRequest('leads', page, params);
    }
    getleads(customer_id) {
        return BaseService.apiGetRequest('getleads/'+customer_id);
    }
	get(id) {
        return BaseService.apiGetRequest('lead/'+id);
    }
    delete(lead) {
        return BaseService.apiPostRequest('lead/delete/', lead);
    }    
    add(lead) {
        return BaseService.apiPostRequest('lead/add', lead);
    }
    sendmessage(lead) {
        return BaseService.apiPostRequest('lead/sendmessage', lead);
    }
    update(id, lead) {
        return BaseService.apiPostRequest('lead/'+id, lead);
    }
}
export default new LeadsService();