<template>
  <el-row :gutter="20">
    <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
    >
      Super Admin Dashboard
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: "SuperAdminDashboard",
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped>
</style>
