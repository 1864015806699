import BaseService from "./base.service";

class TrackpropertyService {
    mostvisitedProperties(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('mostvisitedproperties', page, params);
    }
}
export default new TrackpropertyService();